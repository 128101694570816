<template>
  <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer mr-2">
    <span class="cursor-pointer flex items-center i18n-locale">
      <img class="h-4 w-5" :src="i18n_locale_img" :alt="$i18n.locale" />
      <span class="lang-span hidden sm:block ml-2">{{ getCurrentLocaleData.lang }}</span>
    </span>
    <vs-dropdown-menu class="w-48 vx-navbar-dropdown">
      <vs-dropdown-item @click="updateLocale('en')">
        <div class="flex items-center">
          <img class="h-4 w-5 mr-1" src="@/assets/images/flags/en.png" alt="en" /> 
          <p>&nbsp;English</p>
        </div>
      </vs-dropdown-item>
      <vs-dropdown-item @click="updateLocale('ar')">
        <div class="flex items-center">
          <img class="h-4 w-5 mr-1" src="@/assets/images/flags/ar.png" alt="ar" />
          <p style="font-family: 'cairo', 'Montserrat', Helvetica, Arial, sans-serif;">&nbsp;العربية</p>
        </div>
      </vs-dropdown-item>
    </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>
export default {
  computed: {
    i18n_locale_img () {
      // Use below code to dynamically fetch image instead of if-else
      // NOTE: We used if-else because laravel throws error in 'yarn prod'.
      // If you are not using laravel + Vue, you can use below code to dynamically get image
      // return require(`@/assets/images/flags/${this.$i18n.locale}.png`)

      const locale = this.$i18n.locale
      if (locale === 'en')      return require('@/assets/images/flags/en.png')
      else if (locale === 'ar') return require('@/assets/images/flags/ar.png')
      else return require('@/assets/images/flags/en.png')
    },
    getCurrentLocaleData () {
      if (this.$route.params.lang === 'en')      return { flag: 'us', lang: 'English' }
      else return { flag: 'ar', lang: 'العربية' }
    }
  },
  methods: {
    updateLocale (locale) {
      this.$cookies.set('language', locale, '100y')
      localStorage.setItem('locale', locale)
      if (locale === 'ar') this.$vs.rtl = true
      else if (locale === 'en') this.$vs.rtl = false
      this.$router.push({
        params: {lang: this.$cookies.get('language')}
      }).catch(() => {})
      this.$i18n.locale = this.$cookies.get('language')
    }
  }
}
</script>

<style lang="scss">
@import '../../../../assets/scss/vuexy/_variables.scss';

.lang-span {
  font-family: $font-family-sans-serif;
}

[dir=rtl] .lang-span {
  font-family: $font-family-sans-serif-arabic;
}

</style>